
console.log("version 8/29/24 empty-fasting");

let _BGV_SETTINGS = window.BGV_SETTINGS = window.BGV_SETTINGS || {}

// PROD
_BGV_SETTINGS.ENV = 'PROD'
_BGV_SETTINGS.PARTNER_ID = '018cb48c-a52f-e813-aea0-dd8414bff280'
_BGV_SETTINGS.APP_ID = '018cb4b8-885c-523d-d99d-3976b0b817c0'
_BGV_SETTINGS.PIXEL_ID = '448592461138641'
_BGV_SETTINGS.API_URL = 'https://bgv-backend.getbraavo.com'
_BGV_SETTINGS.EVENTS_API_URL = "https://bgv-event-ingestor.getbraavo.com"

// ------------------ EDIT for each new flow ----------------------
_BGV_SETTINGS.PAGE_SET_ID = '0193544e-cc38-e5da-b171-46bedb518aea'
// ------------------ EDIT for each new flow ----------------------

// ------------------ START STAGING SETTINGS ----------------------
// BGV_SETTINGS.ENV = 'STAGING'
// BGV_SETTINGS.PARTNER_ID = '0019a90b-d133-4ec0-8e67-24559a6b0dd5'
// BGV_SETTINGS.APP_ID = '02132d75-da29-418b-a584-552e6d64943d'
// BGV_SETTINGS.PIXEL_ID = '1321355158702713'
// BGV_SETTINGS.API_URL = 'https://bgv-staging-backend.getbraavo.com'
// BGV_SETTINGS.EVENTS_API_URL = "https://bgv-event-ingestor-staging.getbraavo.com"
// BGV_SETTINGS.PAGE_SET_ID = '018e74e8-3150-901f-d755-85b2f975ee28'
// ------------------ END STAGING SETTINGS ----------------------




_BGV_SETTINGS.renewDisclaimerConf = {
    afterSelectPlanBlock: false,
    afterSubmitButton: true,
};

_BGV_SETTINGS.timerConfig = {
    enable: true,
    buttonText: "Try now",
    countDownSeconds: 60 * 5, // 5 minutes,
    timerDescription: "Discount expires:",
}

_BGV_SETTINGS.lottieConfiguration = {
    'emptyer': {
        'Restrictive diet': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Restrictive_Diet.json',
            'loop': false,
        }
    },
    'predict': {
        'Your goal': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Your_goal_chart_OF.json',
            'loop': false,
        }
    },
    'energy-graph': {
        'Energy graph': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Energy_graph.json',
            'loop': false,
        }
    },
    'hit-your-goal': {
        'Your goal by november': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Your_goal_chart_OF_1.json',
            'loop': false,
        }
    },
    'boost-unlocked': {
        'Unlocked Boost': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Unlocked_boost1.json',
            'loop': false,
        }
    },
    'yo-yo': {
        'Yo-Yo Effect': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Yo-Yo_effect_1_1.json',
            'loop': false,
        }
    },
    'select-plan': {
        'Now-Your Goal': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Now-Your_Goal_organic.json',
            'loop': true,
        }
    },
    'loading-1': {
        'Organic users': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Organic_users.json',
            'loop': false,
        }
    },
    'loading-2': {
        'Organic users 2': {
            'path': 'https://d3bj0c7utzyc4b.cloudfront.net/2024-09/Organic_users.json',
            'loop': false,
        }
    },
}




